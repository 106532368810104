import { Error } from '@epilot360/icons'
import { useTranslation } from 'react-i18next'

import classes from './styles/JourneyUnavailablePage.module.scss'

const JourneyUnavailablePage = () => {
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <Error fill="#DAE0FB" size={64} />
      &nbsp;
      <div>
        {t(
          'journeyUnavailableMessage',
          'Sorry, The page you are looking for is currently unavailable'
        )}
      </div>
    </div>
  )
}

export default JourneyUnavailablePage
