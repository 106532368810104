import { Grid, H2 } from '@epilot/journey-elements'
import type { Contact } from '@epilot/journey-logic-commons'
import type { ReactElement } from 'react'

import {
  ContactFormFields,
  NewContactFormFields
} from '../../../../components/contact/ContactFormFields'
import { useJourneyContext } from '../../../../utils'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { useResetOnNotEqual } from '../../../../utils/hooks/forms/useResetOnNotEqual'
import type { ContactFormProps } from '../types'

export const ContactForm = (props: ContactFormProps): ReactElement => {
  const { context } = useJourneyContext()
  const { journey } = context
  const isNewPI = journey.featureFlags?.['journey-pi-improvements']
  const {
    handleChange,
    path,
    hasError,
    fields,
    data,
    label,
    isRequired,
    id,
    mode
  } = props

  const { control, fieldValues, resetField } = useFormHandler<Contact>({
    handleChange,
    path,
    data,
    isRequired,
    fields,
    formProps: {
      defaultValues: {
        ...data
      }
    }
  })

  useResetOnNotEqual(resetField, {
    name: 'companyName',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.companyName?.defaultValue || '',
    match: fields.companyName?.showIf
  })

  useResetOnNotEqual(resetField, {
    name: 'registerNumber',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.registerNumber?.defaultValue || '',
    match: fields.registerNumber?.showIf
  })

  useResetOnNotEqual(resetField, {
    name: 'registryCourt',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.registryCourt?.defaultValue || '',
    match: fields.registryCourt?.showIf
  })

  return (
    <>
      {label && <H2>{label}</H2>}
      <Grid container spacing={3}>
        {isNewPI ? (
          <NewContactFormFields
            control={control}
            fieldValues={fieldValues}
            fields={fields}
            hasError={hasError}
            id={id}
            mode={mode}
            path={path}
            resetField={resetField}
          />
        ) : (
          <ContactFormFields
            control={control}
            fieldValues={fieldValues}
            fields={fields}
            hasError={hasError}
            id={id}
            path={path}
            resetField={resetField}
          />
        )}
      </Grid>
    </>
  )
}
