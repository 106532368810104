import { datadogRum } from '@datadog/browser-rum'
import { PrivateJourneyError } from '@epilot/journey-elements'
import {
  JOURNEY_ACCESS_MODE,
  initServices as initLogicCommonsServices,
  isLauncherJourney,
  journeyStorage
} from '@epilot/journey-logic-commons'
import type { Journey } from '@epilot/journey-logic-commons'
import {
  ConfigProvider,
  FeatureFlagProvider,
  FeatureFlags,
  JourneyContextProvider,
  OrganizationSettingsContextProvider,
  useHistoryStack
} from '@epilot/json-renderers'
import type { JourneyRenderFlags } from '@epilot/json-renderers'
import type { ComponentProps } from 'react'
import { useEffect, useState } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'

import { setJourneyToken } from './analytics/service'
import { JourneyPage } from './components/JourneyPage'
import JourneyUnavailablePage from './components/JourneyUnavailablePage'
import { SpinnerPage } from './components/SpinnerPage'
import { useAnalytics } from './context/AnalyticsContext'
import { DesignBuilderContextProvider } from './context/DesignBuilderContext'
import useGetJourney from './hooks/useGetJourney'
import { useMessageHandler } from './hooks/useMessageHandler'
import { instance18n, loadRemoteNamespace } from './locales/i18n'
import { env } from './utils/config'
import { extractUUIDsFromParams } from './utils/extractUUIDsFromParams'
import { getInitialHistoryStack } from './utils/getInitialHistoryStack'
import { getParamsByContextValues } from './utils/getParamsByContextValues'
import { checkIfReferredFromJourneyBuilder } from './utils/publishJourneys'
import { scrollToThePageTop } from './utils/scrollToTop'
import { TRACE_KEYS, getTraceId } from './utils/trace'

const rendererConfig = {
  STAGE: env('REACT_APP_STAGE'),
  GOOGLE_MAPS_API_URL: env('REACT_APP_GOOGLE_MAPS_API_URL'),
  PRICING_API_URL: env('REACT_APP_PRICING_API_URL'),
  FILE_API_URL: env('REACT_APP_FILE_API_URL'),
  IMAGE_PREVIEW_API_URL: env('REACT_APP_IMAGE_PREVIEW_API_URL'),
  ADDRESS_API_URL: env('REACT_APP_ADDRESS_API_URL'),
  ADDRESS_SUGGESTIONS_API_URL: env('REACT_APP_ADDRESS_SUGGESTIONS_API_URL'),
  PREVIOUS_PROVIDER_URL: env('REACT_APP_PREVIOUS_PROVIDER_URL'),
  ENTITY_API_URL: env('REACT_APP_ENTITY_API_URL'),
  CUSTOMER_PORTAL_API_URL: env('REACT_APP_CUSTOMER_PORTAL_API_URL'),
  METERING_API_URL: env('REACT_APP_METERING_API_URL'),
  JOURNEYS_API_BASE_URL: env('REACT_APP_JOURNEYS_API_BASE_URL'),
  JOURNEYS_API_URL: env('REACT_APP_JOURNEYS_API_URL')
}

const JOURNEY_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft'
}

export default function App() {
  const {
    journey,
    stepIndex: jbStepIndex,
    debug,
    isLoading,
    initialState,
    preview,
    isLinearJourney,
    journeyId,
    contextData: previewContextData,
    topBarParam,
    modeParam,
    setJourney,
    journeyLanguage: languageParam,
    isEmbedded
  } = useGetJourney()

  const { initializeAnalytics } = useAnalytics()

  useEffect(() => {
    initLogicCommonsServices({
      pricingServiceUrl: env('REACT_APP_JOURNEYS_PRICING_API'),
      addressSuggestionsServiceUrl: env('REACT_APP_ADDRESS_SUGGESTIONS_API'),
      orgId: journey?.organizationId || '',
      publicToken: journey?.settings?.publicToken || ''
    })
  }, [journey?.organizationId, journey?.settings?.publicToken])

  const {
    mode: modeMessage,
    topBar,
    scrollToTop,
    closeButton,
    lang,
    contextData,
    queryParams,
    dataInjectionOptions,
    isFullScreenEntered,
    initialMessageEventReceived
  } = useMessageHandler(journeyId) || {}

  const mode = modeMessage || modeParam || 'full-screen'

  const {
    initialState: injectionInitialState,
    initialStepIndex: injectionStepIndex,
    blocksDisplaySettings
  } = dataInjectionOptions || {}

  const stepIndex = injectionStepIndex || jbStepIndex

  const [launcherJourney, setLauncherJourney] = useState<Journey | null>(null)

  const [sessionStartTime, setSessionStartTime] = useState<Date | null>(null)
  const [shouldRenderJourney, setShouldRenderJourney] = useState(true)

  const isAnalyticsEnabled =
    journey?.featureFlags?.[FeatureFlags.JOURNEY_DATE_REWORK]
  const isPublishJourneyEnabled =
    journey?.featureFlags?.[FeatureFlags.PUBLISH_JOURNEY]

  useEffect(() => {
    setSessionStartTime(new Date())
  }, [])

  useEffect(() => {
    /* 
      TODO: initially analytics is collected for all organizations, add journey?.journeyAnalyticsEnabled to control based on organization settings later
    */
    try {
      if (
        journey?.journeyId &&
        isAnalyticsEnabled &&
        !journeyFlags?.isPreview
      ) {
        const privateToken = journeyStorage?.getItem('token') || ''
        const publicToken = journey?.settings?.publicToken || ''
        const token = privateToken || publicToken

        setJourneyToken(token)
        initializeAnalytics({
          journeyId: journey?.journeyId,
          journeyName: journey?.name,
          sessionStartTime: sessionStartTime || new Date(),
          journeyLoadEndTime: new Date()
        })
      }
    } catch (err) {
      console.error('Error initializing analytics', err)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journey?.journeyId])

  const history = useHistoryStack(
    {
      stepName: journey?.steps?.[stepIndex]?.name || '',
      stepIndex
    },
    scrollToThePageTop,
    journey?.journeyId || '',
    getInitialHistoryStack(journey?.steps, injectionStepIndex),
    {
      scrollIntoView:
        typeof scrollToTop === 'undefined' || scrollToTop === true,
      forceInitialize: preview,
      isTrackingDisabled: mode === 'full-screen' && !isFullScreenEntered
    },
    datadogRum
  )

  /* On initial load, if journey is launcher journey, store it */
  useEffect(() => {
    if (journey && !launcherJourney && isLauncherJourney(journey.steps)) {
      setLauncherJourney(journey)
    }
  }, [launcherJourney, journey])

  const journeyFlags: JourneyRenderFlags = {
    debug,
    isLinear: isLinearJourney,
    isPreview: preview,
    showCloseButton: closeButton,
    showTopBar:
      typeof topBar === 'boolean'
        ? topBar
        : typeof topBarParam === 'boolean'
          ? topBarParam
          : true,
    mode: mode,
    scrollToTop: scrollToTop || false
  }

  useEffect(() => {
    try {
      const referrer = document?.referrer || ''
      const journeyBuilderUrl = env('REACT_APP_JOURNEY_BUILDER_URL')
      const isReferredFromJourneyBuilder = checkIfReferredFromJourneyBuilder(
        referrer,
        journeyBuilderUrl
      )

      // if journey is not published and not opened from journey builder, don't render the journey
      // check isPublished status to be false explicitly as older journeys might not have this field and those journeys should not be restricted
      if (
        isPublishJourneyEnabled &&
        journey?.settings?.status === JOURNEY_STATUS.DRAFT &&
        !isReferredFromJourneyBuilder &&
        !journeyFlags?.isPreview
      ) {
        setShouldRenderJourney(false)
      }
    } catch (err) {
      console.warn('Error checking journey render logic', err)
    }
  }, [
    isPublishJourneyEnabled,
    journey?.settings?.status,
    journeyFlags?.isPreview
  ])

  useEffect(() => {
    instance18n.changeLanguage(lang || languageParam)

    loadRemoteNamespace(lang || languageParam)
  }, [languageParam, lang])

  const contextParameter = extractUUIDsFromParams({
    ...getParamsByContextValues(queryParams || {}, journey?.contextSchema),
    ...(contextData || previewContextData)
  })

  const contextValues = {
    ...contextParameter,
    embedded_in: document.referrer,
    journey_url_used: document.location.href
  }

  const forceRemountAfterInjection = injectionInitialState
    ? JSON.stringify(injectionInitialState)
    : 'normalcontext'

  const authToken = journeyStorage.getItem('token')

  // if access mode is private and no token is present, show error
  // or if the journey is not found and not loading, show error
  if (
    journey?.settings?.accessMode === JOURNEY_ACCESS_MODE.PRIVATE &&
    !authToken // ||
    // (!journey && !isLoading)
  ) {
    return (
      <I18nextProvider i18n={instance18n}>
        <PrivateJourneyErrorWrapper embedMode={mode} />
      </I18nextProvider>
    )
  }

  return journey && !isLoading ? (
    <FeatureFlagProvider featureFlags={journey.featureFlags}>
      <ConfigProvider config={rendererConfig}>
        <JourneyContextProvider
          blocksDisplaySettings={blocksDisplaySettings}
          contextValues={contextValues}
          history={history}
          initialState={injectionInitialState || initialState}
          isPreview={preview}
          journey={journey}
          key={forceRemountAfterInjection}
          sessionIdGetter={sessionIdGetter}
          shouldCheckContextValues={
            isEmbedded ? !!initialMessageEventReceived : true
          }
        >
          <OrganizationSettingsContextProvider
            settings={journey.settings?.organizationSettings}
          >
            <DesignBuilderContextProvider>
              <I18nextProvider i18n={instance18n}>
                {shouldRenderJourney ? (
                  <JourneyPage
                    contextData={contextValues}
                    embedMode={mode}
                    flags={journeyFlags}
                    history={history}
                    initialStepValues={initialState}
                    journey={journey}
                    key={journey.journeyId}
                    launcherJourney={launcherJourney}
                    onJourneyChange={setJourney}
                  />
                ) : (
                  <JourneyUnavailablePage />
                )}
              </I18nextProvider>
            </DesignBuilderContextProvider>
          </OrganizationSettingsContextProvider>
        </JourneyContextProvider>
      </ConfigProvider>
    </FeatureFlagProvider>
  ) : (
    <SpinnerPage />
  )
}

App.displayName = 'App'

export const sessionIdGetter = () => getTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)

export const PrivateJourneyErrorWrapper = (
  props: ComponentProps<typeof PrivateJourneyError>
) => {
  const { t } = useTranslation()

  return (
    <PrivateJourneyError
      embedMode={props.embedMode}
      i18n={{
        heading: t('page_not_found.title'),
        content: t('page_not_found.message')
      }}
    />
  )
}
