import { LOCALE_MAP } from '@epilot/journey-logic-commons'
import classNames from 'classnames'
import { de } from 'date-fns/locale'
import type { MouseEventHandler } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import DatePickerComponent, { registerLocale } from 'react-datepicker'
import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { DatePickerFooter } from './components/DatePickerFooter'
import { DatePickerHeader } from './components/DatePickerHeader'
import { DatePickerTextField } from './components/DatePickerTextField'
import classes from './DatePicker.module.scss'
import type {
  DatePickerCSSProperties,
  DatePickerProps,
  WeekDays
} from './types'
import { findNearestAvailableDay, useCalendarObserver } from './utils'

registerLocale('de', de)

export const DatePicker = ({
  isDisabled,
  id,
  date: value,
  onChange,
  isTimeSelectVisible,
  timeIntervals = 30,
  yearDiff = 600,
  dateFormat,
  timeFormat,
  style,
  calendarBgColor,
  separationColor,
  selectedColor,
  selectedBgColor,
  inputProps,
  label,
  isRequired,
  isError,
  className,
  locale,
  disableDays,
  minDate,
  maxDate,
  ...props
}: DatePickerProps) => {
  const isMountedRef = useRef(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { isCalendarOpen, datePickerRef, setIsCalendarOpen, calendarRef } =
    useCalendarObserver()

  const [date, setDate] = useState<Date | null>(value || null)
  const [isMonthSelectOpen, setIsMonthSelectOpen] = useState(false)
  const [isYearSelectOpen, setIsYearSelectOpen] = useState(false)

  const isHeaderSelectOpen = isMonthSelectOpen || isYearSelectOpen

  const customStyles: DatePickerCSSProperties = {
    ...style,
    '--concorde-datepicker-calendar-bg-color': calendarBgColor,
    '--concorde-datepicker-separation-color': separationColor,
    '--concorde-datepicker-selected-color': selectedColor,
    '--concorde-datepicker-selected-bg-color': selectedBgColor
  }

  function resetMonthYear() {
    setIsMonthSelectOpen(false)
    setIsYearSelectOpen(false)
  }

  const handleCalendarOpen = () => {
    const today = new Date()
    const nearestAvailableDay = findNearestAvailableDay({
      today,
      disableDays,
      maxDate,
      minDate
    })

    setDate(nearestAvailableDay)
  }

  function toggleCalendar() {
    if (isDisabled) {
      return
    }
    if (isCalendarOpen) {
      resetMonthYear()
    } else {
      handleCalendarOpen()
    }
    setIsCalendarOpen((prev) => !prev)
  }

  function resetDate() {
    setDate(value || new Date())
    toggleCalendar()
  }

  // Exclude interacting with textfield from closing calendar
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const path = e?.composedPath()

      const datePickerTextField = inputRef.current?.parentElement?.parentElement

      if (
        datePickerTextField &&
        datePickerTextField instanceof HTMLElement &&
        path?.includes(datePickerTextField)
      ) {
        return
      }

      setIsMonthSelectOpen(false)
      setIsYearSelectOpen(false)
      setIsCalendarOpen(false)
    },
    [setIsCalendarOpen]
  )

  // Resets local state when external value changes
  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true

      return
    }

    if (value) {
      setDate(value)
    }
  }, [value])

  return (
    <div
      className={classNames(
        'Concorde-DatePicker',
        classes.root,
        // Handle custom header select
        isHeaderSelectOpen && [
          'Concorde-DatePicker--Select_View',
          classes.selectView
        ],
        classes.allSelect,
        !isTimeSelectVisible && classes.noTimeSelect,
        className
      )}
      id={id}
      ref={datePickerRef}
      style={customStyles}
    >
      <DatePickerComponent
        {...props}
        calendarClassName={classNames(
          'Concorde-DatePicker__Calendar',
          classes.calendar,
          isHeaderSelectOpen && classes.monthYearSelection
        )}
        customInput={
          <DatePickerTextField
            inputId={id}
            isDisabled={isDisabled}
            isError={isError}
            isRequired={isRequired}
            label={label}
            ref={inputRef}
            toggleCalendar={toggleCalendar}
            {...inputProps}
          />
        }
        customInputRef="id" // Hack to pass ref to DatePickerTextField
        dateFormat={
          dateFormat
            ? dateFormat
            : isTimeSelectVisible
              ? 'dd/MM/yyyy, HH:mm'
              : 'dd/MM/yyyy'
        }
        dayClassName={() => classNames('Concorde-DatePicker__Day', classes.day)}
        disabled={isDisabled}
        dropdownMode="select"
        filterDate={(date: Date) => {
          if (!date) {
            return false
          }

          return !disableDays?.includes(date.getDay() as WeekDays)
        }}
        locale={LOCALE_MAP[locale]}
        maxDate={maxDate}
        minDate={minDate}
        onCalendarClose={resetMonthYear}
        onChange={(newDate: Date | null) => {
          setDate(newDate)
          if (onChange) {
            onChange(newDate)
          }
        }}
        onClickOutside={
          handleClickOutside as unknown as MouseEventHandler<HTMLElement>
        }
        open={isCalendarOpen}
        popperPlacement="bottom-end"
        ref={calendarRef}
        renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => (
          <DatePickerHeader
            {...props}
            isMonthSelectOpen={isMonthSelectOpen}
            isTimeSelectVisible={isTimeSelectVisible}
            isYearSelectOpen={isYearSelectOpen}
            setMonthSelectOpen={setIsMonthSelectOpen}
            setYearSelectOpen={setIsYearSelectOpen}
            yearDiff={yearDiff}
          />
        )}
        required={isRequired}
        selected={date}
        shouldCloseOnSelect={false}
        showPopperArrow={false}
        showTimeSelect={isTimeSelectVisible}
        timeClassName={() =>
          classNames(
            'Concorde-DatePicker__Time-list-item',
            classes.timeListItem
          )
        }
        timeFormat={timeFormat || 'HH:mm'} // We have to specify this also for some reason
        timeIntervals={timeIntervals}
        toggleCalendar={toggleCalendar}
        toggleCalendarOnIconClick={true}
        wrapperClassName="Concorde-DatePicker__Wrapper"
      >
        <DatePickerFooter
          closeCalendar={toggleCalendar}
          isHeaderSelectOpen={isHeaderSelectOpen}
          resetDate={resetDate}
        />
      </DatePickerComponent>
    </div>
  )
}
