export const checkIfReferredFromJourneyBuilder = (
  referrer: string,
  journeyBuilderUrl: string
) => {
  try {
    const referrerOrigin = referrer ? new URL(referrer)?.origin : ''

    return referrerOrigin === journeyBuilderUrl
  } catch (err) {
    return false
  }
}
